body, html {
  height: 100%;
  scroll-behavior: auto;
  margin: 0;
  padding: 0;
}
#root {
  min-height: 100%;
  scroll-behavior: auto;
  margin: 0;
  padding: 0;
}
#root {
  display: flex;
  flex-direction: column
}
a {
  color: #0097FF
}
